import { BASE_URL } from '@/api/api'
import { request, METHOD } from '@/utils/request'

const URL = {
  index: BASE_URL + '/home/banner', // 海报bannner
  category: BASE_URL + '/home/quick/access', // 金刚区
  floor: BASE_URL + '/home/floor', // 楼层
  indexMoreLabel: BASE_URL + '/active/search/label', // 首页筛选条件
  floorMoreLabel: BASE_URL + '/home/floor/more/label', // 楼层筛选条件
  moreFloorActive: BASE_URL + '/home/floor/more/active', // 楼层更多活动
  AccessMoreLabel: BASE_URL + '/home/quick/access/more/label', // 金刚区筛选条件
  moreAccessActive: BASE_URL + '/home/quick/access/more/active', // 金刚区更多活动`
  themeDetail: BASE_URL + '/theme/', // 专题详情
  activeDetail: BASE_URL + '/active/detail', // 活动详情
  search: BASE_URL + '/active/search',

  getProjectLabelOptions: BASE_URL + '/home/label/project', // 获取项目类型下的标签
  getMajorLabelOptions: BASE_URL + '/home/label/major', // 获取相关专业下的标签
  getGradeLabelOptions: BASE_URL + '/home/label/grade', // 获取参与年级下的标签

  getHomeLabel: BASE_URL + '/home/label', // 根据分类获取标签组树/{typeId}
  getClassTypeList: BASE_URL + '/home/type', // 获取分类列表
}

// 获取金刚区
export async function getCategory() {
  return request(URL.category, METHOD.GET)
}
// 获取楼层
export async function getFloor() {
  return request(URL.floor, METHOD.GET)
}
// 金刚区更多列表
export async function moreAccessActive(query) {
  return request(URL.moreAccessActive, METHOD.GET, query)
}
// 楼层区更多列表
export async function moreFloorActive(query) {
  return request(URL.moreFloorActive, METHOD.GET, query)
}
// 活动详情
export async function activeDetail(query) {
  return request(URL.activeDetail, METHOD.GET, query)
}
export async function getFileDetail(id) {
  return request(`${editFileUrl}/${id}`, METHOD.GET)
}

// 获取项目类型下的标签
export async function getProjectLabelOptions() {
  return request(URL.getProjectLabelOptions, METHOD.GET)
}
// 获取相关专业下的标签
export async function getMajorLabelOptions() {
  return request(URL.getMajorLabelOptions, METHOD.GET)
}
// 获取项目类型下的标签
export async function getGradeLabelOptions() {
  return request(URL.getGradeLabelOptions, METHOD.GET)
}

//
export async function getHomeLabels(id) {
  return request(URL.getHomeLabel + '/' + id, METHOD.GET)
}
//

export async function getClassTypeLists() {
  return request(URL.getClassTypeList, METHOD.GET)
}

// 全局搜素
export async function search(data) {
  return request(URL.search, METHOD.GET, data)
}
