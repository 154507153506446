window.x_env = 'production' //testing,staging,production
window.x_version = '0116_1100_v1.2.0' //版本号时间戳
let env = window.x_env
let version = window.x_version
console.log('env', env, 'version', version)
sessionStorage.setItem('ver', `version:${version},env:${env}`)
if (window.location.href.indexOf('dev') != -1 || env.indexOf('testing') != -1) {
  //测试环境接口地址
  // var BASE_URL = 'http://eamaster-weapp.dev.carlinx.cn/api'
  var BASE_URL = 'http://eamaster.test.carlinx.cn/api'
} else if (env.indexOf('staging') != -1) {
  //演示环境地址
  // var BASE_URL = 'https://weapp.eamaster.com/api'
  var BASE_URL = 'https://eamaster.com/api'
} else {
  //开发环境地址
  // var BASE_URL = 'https://weapp.eamaster.com/api'
  var BASE_URL = 'https://eamaster.com/api'
}

module.exports = {
  BASE_URL,
}
