import Axios from 'axios'
import router from '../router'
import { message } from 'ant-design-vue'
const qs = require('qs')

let axios = Axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 1000 * 12,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

// 添加json-bigint

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params) {
  switch (method) {
    case METHOD.GET:
      return axios({
        method: 'get',
        url: url,
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
    case METHOD.PUT:
      return axios.put(url, params)
    case METHOD.POST:
      return axios.post(url, params)
    case METHOD.DELETE:
      if (params && params.delRequestType) {
        //delRequestType为true delect请求方式将参数传递到请求体
        return axios({ method: 'delete', url, data: params.data })
      } else {
        return axios.delete(url, { params })
      }
    default:
      return axios.get(url, { params })
  }
}

/**
 * 提示函数
 * 显示一秒后关闭
 */
const tip = (msg) => {
  this.$message({
    message: msg,
    duration: 1000,
    forbidClick: true,
  })
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/',
    query: {
      redirect: router.currentRoute.fullPath,
    },
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
// eslint-disable-next-line
const errorHandle = (status, other) => {
  switch (status) {
    case 401:
      tip('登录过期，请重新登录')
      break
    case 403:
      tip('登录过期，请重新登录')
      // 清除token
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
    case 404:
      tip('网络请求不存在')
      break
    default:
      tip(other)
  }
}

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么(后期在这里加上token)
    config.headers['Content-Type'] = 'application/json'
    /*  if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    } */
    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  // 请求成功
  (res) => {
    if (!res.config.loading) {
    }
    if (res.status === 200) {
      if (res.data.success || res.data.StatusCode == 200) {
        return Promise.resolve(res.data)
      } else {
        // message(res.data.message || "请求错误");
        return Promise.resolve(res.data)
      }
    } else if (res.statusCode === 404 || res.statusCode === 500) {
    }
  },
  // 请求失败
  (error) => {
    if (!error.config.loading) {
    }
    const { response } = error
    if (response) {
      // 请求已发出，但是不在200的范围
      if (error.request.status == 500) {
        console.log('error.request.status == 500', response)
        var img = new Image()
        //临时判断网络是否通畅
        img.src = 'https://www.baidu.com/favicon.ico?_t=' + Date.now()
        img.onload = function () {
          //('提示','服务器报500了，请检查');
          message('访问不到服务器，请稍后尝试！')
        }
        img.onerror = function () {
          //'提示','断网了，请注意您的网络连接';
          message('响应超时，请检查网络环境！')
        }
      }
      console.log('Promise.reject', response)
      message('响应超时，请检查网络环境！')
      return Promise.reject(response)
    } else {
      return Promise.reject(error)
    }
  }
)

export { METHOD, request }
